
/*! no-touch & no-js | https://gist.github.com/mhulse/4704893 */
(function(window, document, navigator) {
  ("ontouchstart" in window ||
    (window.DocumentTouch && document instanceof DocumentTouch) ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0) &&
    (document.documentElement.className = document.documentElement.className.replace(
      /\bno-touch\b/,
      "touch"
    ));
  document.documentElement.className = document.documentElement.className.replace(
    /\bno-js\b/,
    "js"
  );
})(window, document, navigator);

/*! no-touch & no-js uglified | https://gist.github.com/mhulse/4704893 */ !(function(
  e,
  n,
  o
) {
  ("ontouchstart" in e ||
    (e.DocumentTouch && n instanceof DocumentTouch) ||
    o.MaxTouchPoints > 0 ||
    o.msMaxTouchPoints > 0) &&
    (n.documentElement.className = n.documentElement.className.replace(
      /\bno-touch\b/,
      "touch"
    )),
    (n.documentElement.className = n.documentElement.className.replace(
      /\bno-js\b/,
      "js"
    ));
})(window, document, navigator);

/*! no-touch | https://gist.github.com/mhulse/4704893 */
(function(window, document, navigator) {
  ("ontouchstart" in window ||
    (window.DocumentTouch && document instanceof DocumentTouch) ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0) &&
    (document.documentElement.className = document.documentElement.className.replace(
      /\bno-touch\b/,
      "touch"
    ));
})(window, document, navigator);

/*! no-touch uglified | https://gist.github.com/mhulse/4704893 */ !(function(
  o,
  n,
  c
) {
  ("ontouchstart" in o ||
    (o.DocumentTouch && n instanceof DocumentTouch) ||
    c.MaxTouchPoints > 0 ||
    c.msMaxTouchPoints > 0) &&
    (n.documentElement.className = n.documentElement.className.replace(
      /\bno-touch\b/,
      "touch"
    ));
})(window, document, navigator);

/*! no-js | https://gist.github.com/mhulse/4704893 */
(function(document) {
  document.documentElement.className = document.documentElement.className.replace(
    /\bno-js\b/,
    "js"
  );
})(document);

/*! no-js uglified | https://gist.github.com/mhulse/4704893 */ !(function(e) {
  e.documentElement.className = e.documentElement.className.replace(
    /\bno-js\b/,
    "js"
  );
})(document);
