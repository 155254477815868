import $ from 'jquery';


export default class Sticky {
    init() {

        let element = $('[data-sticky]');

        if (!element.length) return;
        
        if (prestashop.responsive.mobile) {
            element.sticky();
        } else {
            $(document).on('scroll', function () {
                var scrollTop = $(document).scrollTop();
                if (scrollTop > 200) {
                    if (!$("#header").find('.sticky-wrapper').length) {
                        document.querySelector('[data-sticky]').style.transform = "translateY(-100%)";
                    }
                    element.sticky();
                    element.on('sticky-start', function () {
                        this.style.transform = "translateY(0)";
                    });
                } else {
                    element.unstick();
                    element.removeAttr('style');
                }

            });
        }



        element.on('sticky-end', function () {
            $(this)
                .parent()
                .css("height", "auto");
        });
    }

}



